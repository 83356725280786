//@ts-check
document.addEventListener("DOMContentLoaded", () => {
  var scrollTopButton = document.getElementById("scroll-top");
  if (scrollTopButton) {
    scrollTopButton.addEventListener("click", () => {
      jQuery("html,body").animate(
        {
          scrollTop: jQuery("body").offset().top
        },
        500
      );
    });
  }
});
